<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac">
					<div>
						<span class="mr-20">系列名字：</span>
						<el-select class="w-250 mr-30" v-model="actyForm.id" clearable placeholder="请选择活动名称">
							<el-option v-for="(item,index) in actList" :key="item.id" :value="item.id"
								:label="item.helpActyName"></el-option>
						</el-select>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">用户名：</span>
						<el-input class="w-250" v-model="actyForm.userName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">手机号：</span>
						<el-input class="w-250" v-model="actyForm.userPhone" clearable></el-input>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:userImg="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg" />
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 每日福利 新人活动 道具 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="10vh" width="30%" @close="resetForm">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="邀请人数" prop="invUsrNum" v-if="'invUsrNum' in ruleForm"
					:rules="{required: true, message: '累计消费不能为空', trigger: 'blur'}">
					<el-input v-model="ruleForm.invUsrNum"></el-input>
				</el-form-item>
				<el-form-item label="能量值" prop="energy" v-if="'energy' in ruleForm"
					:rules="{required: true, message: '能量值不能为空', trigger: 'blur'}">
					<el-input v-model="ruleForm.energy"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	export default {
		name: 'gatherCard',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				retrieveForm: {
					searchValue: '',
				},
				tabLoading: false,
				butLoading: false,
				dialogTitle: '编辑',
				dialogFormVisible: false,
				columns: [{
					columnType: 'custom',
					label: '头像',
					prop: 'userImg',
					align: 'left',
					width: '80'
				}, {
					label: '用户名称',
					prop: 'userName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '手机号',
					prop: 'userPhone',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '排名',
					prop: 'rank',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '邀请人数',
					prop: 'invUsrNum',
					align: 'left',
					width: '300'
				}, {
					label: '能量值',
					prop: 'energy',
					align: 'left',
					width: '300'
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '150',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.editList
					}],
				}, ],
				actyForm: {
					id: '',
					userName:'',
					userPhone:''
				},
				ruleForm: {
					invUsrNum: '', //道具名称
					energy: '', //道具类型
				},
				tableData: [],
				actList: [],
			}
		},
		mounted() {
			this.findHelpActyList()
			// this.getTableData()
		},
		methods: {
			//查询
			submitSearch() {
				if (this.actyForm.id) {
					this.getTableData()
				} else {
					this.$message({
						message: '请选择类型',
						type: 'error'
					})
				}
			},
			findHelpActyList() {
				this.$http.get('/helpActy/findHelpActyList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
					}
				}).then(({
					data: result
				}) => {
					this.actList = result.data
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/actPart/findUsrActPartsByActyId', {
					params: {
						actyId: this.actyForm.id,
						phone: this.actyForm.userPhone,
						userId: '',
						userName: this.actyForm.userName,
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//编辑列表
			editList(row) {
				this.dialogTitle = "编辑"
				this.ruleForm = JSON.parse(JSON.stringify(row))
				this.dialogFormVisible = true
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ""
						if (this.dialogTitle == '编辑') {
							apiUrl = "/actPart/editUsrActPart"
						}
						this.$http.post(apiUrl, this.ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
							this.getTableData()
							this.dialogFormVisible = false
						}).catch((err) => {
							this.butLoading = false
						})
					}
				})
			},
			//重置清除校验
			resetForm() {
				Object.assign(this.$data.ruleForm, this.$options.data().ruleForm)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>